<!-- eslint-disable -->
<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Mon Profile
            </div>

            <div class="subtitle-1 font-weight-light">
              Voir mes informations personelles
            </div>
          </template>

          <b-form @submit.prevent="updateProfile">
              <v-row>
        <v-col cols="12" md="6">
          <v-text-field required v-model="provider.email" label="Email" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field required v-model="provider.password" label="Mot de passe" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field required v-model="provider.name" label="Nom" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field required v-model="provider.phone" placeholder="phone1/phone2" label="N° Téléphone" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <label>Wilaya</label>
          <b-form-select required v-model="selectedWilaya" @change="provider.wilaya = selectedWilaya.name">
            <option v-for="wilaya in wilayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
          </b-form-select>
        </v-col>
        <v-col cols="12" md="6">
          <label>Commune</label>
          <b-form-select required v-model="selectedCommunue" @change="provider.communue = selectedCommunue">
            <option v-for="communue in selectedWilaya.communues" :key="communue.name" :value="communue.name">{{communue.name}}</option>
          </b-form-select>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field required v-model="provider.address" label="Adresse" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6" v-if="provider.type == 'driver'">
          <v-text-field required v-model="provider.code" placeholder="XXXXX-XXX-XX" label="Matricule" class="purple-input"/>
        </v-col>
        <v-col v-if="provider.type == 'driver'"
          cols="12"
          md="6"
        >
          <label>Type de Véhicule</label>
          <b-form-select v-model="provider.selectedVehicule" required>
            <option v-for="(vehicule,index) in vehicules" :key="index" :value="vehicule">{{vehicule}}</option>
          </b-form-select>
        </v-col>
        <v-col v-if="provider.type == 'driver'"
          cols="12"
          md="6"
        >
          <label>Type de Service (à rejoindre)</label>
          <b-form-select v-model="provider.selectedService" required>
            <option v-for="(vehicule,index) in ['Service SOS (Auto Dépannage)','Service Logistique', 'Service Express', 'Logistique/Express']" :key="index" :value="vehicule">{{vehicule}}</option>
          </b-form-select>

        </v-col>
        </v-row>
        <v-row v-if="provider.type == 'driver'">
        <b style="padding:10px">Documents renseignés</b>
        </v-row>
        <v-row v-for="file in provider.files" :key="file.file_name">
          <span style="padding:10px"><strong>{{file.doc_name}}:</strong><a target="_blank" style="margin-left:10px" :href="hostName+file.link">{{file.file_name}}</a></span>
        </v-row>
        <v-row>
        <v-col cols="12" md="6" class="text-left" v-if="provider.type == 'driver'">
          <v-btn @click="addDocs" style="background-color:#efefef;color:black;padding:5px;border-radius:5px">
            Ajouter un nouveau document
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" class="text-right">
          <v-btn type="submit" style="background-color:#4caf50" class="mr-0">
            Confirmer
          </v-btn>
        </v-col>
        </v-row>
        <v-row v-for="(doc,i) in docs" :key="doc.id">
          <v-col cols="12" md="6">
            <v-text-field required v-model="doc.name" label="Nom de document" class="purple-input"/>
          </v-col>
          <v-col cols="12" md="5">
            <!-- <input type="file" id="myfile" name="myfile"><br><br> -->
            <input required style="margin-top:15px" type="file" id="file" ref="file" v-on:change="loadFileName(i)" accept=".pdf, .jpg, .jpeg, .png">
          </v-col>
          <v-col cols="12" md="1">
            <v-icon @click="removeDoc(i)">mdi-close</v-icon>
          </v-col>
        </v-row>
          </b-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
  import axios from 'axios'
  import Multiselect from 'vue-multiselect'
  export default {
    //
    data() {
      return{
        docs: [],
        vehicules: ['Fourgon 400 KG','Fourgon 1.5 TN', 'Camion 2.5 TN', 'Camion 3.5 TN', 'Camion 10 TN', 'Camion 20 TN'],
        isMultiple:false,
        addr1:null,
        addr2:null,
        responseMessage: null,
        produit: null,
        provider: null,
        selectedWilaya: {
          name:null,
          communues: []
        },
        selectedCommunue: null,
        wilayas:null,
        selectedVehicule: null,
        selectedMethod:null,
        wilaya: null,
        posts: [],
        wilaya2: {dlv_type:'both'},
        checked: false,
        status: false,
        clientname: null,
        receiverphone:null,
        receivername:null,
        prix: null,
        phone: null,
        willaya: null,
        address: null,
        pickup: {
        },
        county: null,
        tracking_id: null,
        fais: null,
        date: null,
        text: null,
        setset: null,
        fuckoff: null,
        willayas: [],
        communue: null,
        communues: [],
        prixtota: null,
        weight:null,
        quantity:null,
        documents:null,
        hostName: null,
      }
    },
    mounted(){
      this.pickup = {
        date:new Date(),
        time:'00:00'
      }
      this.hostName = process.env.VUE_APP_MEDIA_URL.replace('/media/','')
      this.date = new Date().toDateString()
      axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
      this.getWilayas()
    },
    methods:{
      loadFileName(index) {
        this.docs[index].file_name = this.$refs.file[index].files[0].name
      },
      addDocs() {
        this.docs.push({
          id:this.docs.length,
          name: '',
          file_name: '',
          content: ''
        })
        console.log(this.docs)
      },
      removeDoc(index) {
        this.docs.splice(index,1)
        console.log(this.docs)
      },
      getWilayas() {
      axios.post('get-wilayas').then(response => {
          if (response.data.status == 'success') {
            this.wilayas = response.data.data
          }
          this.detectingprovider()
        })
      },
      gettingwilayas(){
      axios.post('get-wilayas').then(response => {

        // for (let i=0;i<response.data.data.length;i++){
          this.willayas = response.data.data
          console.log(this.willayas)
        // }
      })

    },
    changeCommunues() {
      console.log(this.willaya)
      this.communues=this.willaya.communues
    },
    updateProfile() {
      axios.post('admin-change-profile', {
        auth_token: localStorage.getItem('auth_token'),
        user_info: {
          auth_token: this.provider.auth_token,
          email:this.provider.email,
          password:this.provider.password,
          name: this.provider.name,
          phone: this.provider.phone,
          code: this.provider.code,
          wilaya:this.selectedWilaya.name,
          communue:this.selectedCommunue,
          address:this.provider.address,
          selectedVehicule: this.provider.selectedVehicule,
          selectedService: this.provider.selectedService,
          user_type: this.provider.type,
        }
      }).then(response => {
        let message = document.createElement("span");
        message.innerHTML = '<strong style="color:green">Vos données sont mise à jour avec success</strong>'
        document.getElementsByTagName('form')[0].appendChild(message)
        if (this.docs.length > 0) {
          let formData = new FormData()
          for (let i in this.docs) {
            formData.append('file'+i,this.$refs.file[i].files[0])
          }
          formData.append('files_info', JSON.stringify(this.docs))
          formData.append('post_info', JSON.stringify(this.provider))
          axios.post('driver-add-docs',formData,{
            headers: {
                  'Content-Type': 'multipart/form-data'
            },
          }).then(response => {
          })
        }
      })
    },
    createDelivery() {
      this.responseMessage = null
      let client_wilaya = null
      if (this.willaya == null) client_wilaya = null
      else client_wilaya = this.willaya.name
      if (this.isMultiple) {
        let ii = this.addr2
        this.addr2 = ''
        for (let i in ii) {
          this.addr2 += ii[i] + '****'
        }
      }
      axios.post('create-delivery',{
        auth_token: localStorage.getItem('auth_token'),
        selectedMethod:this.selectedMethod,
        produit: this.produit,
        pickup:this.pickup,
        wilaya1: this.selectedWilaya1.name,
        wilaya2: this.selectedWilaya2.name,
        communue1: this.selectedCommunue1,
        communue2: this.selectedCommunue2,
        quantity:this.quantity,
        documents:this.documents,
        addr1: this.addr1,
        addr2: this.addr2,
        weight:this.weight,
        text:this.text,
        clientname: this.clientname,
        receiverphone:this.receiverphone,
        receivername:this.receivername,
        phone: this.phone,
        delivery_type:this.selectedVehicule,
        prix: this.fais
      }).then(response =>{
        if (response.data.status == 'error') {
          this.responseMessage = response.data.error_message
        } else {
          this.tracking_id = response.data.data.track_id
          this.setset = response.data.status
          this.produit = null
          this.wilaya1 = null
          this.wilaya2 = null
          this.selectedCommunue2 = null
          this.selectedCommunue1 = null
          this.checked = false
          this.status = false
          this.clientname = null
          this.receivername = null
          this.receiverphone = null
          this.prix = null
          this.phone = null
          this.willaya = null
          this.address = null
          this.county = null
          this.text = null
        }
      })
    },
      creatcoli(){
        this.responseMessage = null
        let client_wilaya = null
        if (this.willaya == null) client_wilaya = null
        else client_wilaya = this.willaya.name
        axios.post('createcolis',{
          produit: this.produit,
          quantity: this.quantity,
          checked: this.checked,
          wilaya: this.wilaya,
          wilaya2: this.wilaya2.code,
          selectedCommunue1: this.selectedCommunue1,
          selectedCommunue2: this.selectedCommunue2,
          auth_token: localStorage.getItem('auth_token'),
          willaya: client_wilaya,
          address: this.address,
          communue: this.county,
          clientname: this.clientname,
          phone: this.phone,
          prix: this.prix,
          status: this.status,
          fees:this.fais
        }).then(response =>{
          if (response.data.status == 'error') {
            this.responseMessage = response.data.error_message
          } else {
            this.tracking_id = response.data.data.track_id
            this.setset = response.data.status
            this.prixtota = parseInt(this.prix) + parseInt(this.fais)
            this.produit = null
            this.wilaya = null
            this.wilaya2 = {dlv_type:'both'}
            this.selectedCommunue1 = null
            this.selectedCommunue2 = null
            this.checked = false
            this.status = false
            this.clientname = null
            this.prix = null
            this.phone = null
            this.willaya = null
            this.address = null
            this.county = null
            this.text = null
          }
        })

      },
      changeFees() {
        this.prix = 0
        this.getFees()
      },
      getFees() {
        if (this.selectedWilaya1!=null && this.selectedWilaya2!=null && this.selectedVehicule!=null) {
            axios.post('get-btw', {
            auth_token: localStorage.getItem('auth_token'),
            w1: this.selectedWilaya1.name,
            w2: this.selectedWilaya2.name,
            c1: this.selectedCommunue1,
            c2: this.selectedCommunue2,
            selectedVehicule: this.selectedVehicule,
          }).then(response => {
            if (response.data.status == 'success') {
              console.log(response.data.data)
              this.fais = response.data.data
              let multiple = response.data.multiple
              if (this.isMultiple) {
                this.fais = this.fais + multiple * (this.addr2.length - 1)
              }
              this.responseMessage = null
            } else if (response.data.error_message != undefined) {
              this.responseMessage = response.data.error_message
              this.fais = null
            }
          })
        } else {
          this.fais = null
          this.responseMessage = null
        }
        // if (this.wilaya2.dlv_type == "homedlv"){
        //   this.checked=true
        // }
        // else if (this.wilaya2.dlv_type == "stopdesk"){
        //   this.checked=false
        // }
        // if (this.wilaya2.code != null && this.wilaya != null) {
        //   if (this.wilaya2.dlv_type == "both" && this.wilaya2.code == this.wilaya) {
        //     this.checked=true
        //   }
        //   axios.post('get-between-posts-fees', {
        //     auth_token: localStorage.getItem('auth_token'),
        //     post1: this.wilaya,
        //     post2: this.wilaya2.code,
        //     home_fees: this.checked
        //   }).then(response => {
        //     console.log('HERE')
        //     if (response.data.status == 'success') {
        //       if (this.status == true) {
        //         this.fais = 0
        //       }
        //       else this.fais = response.data.data
        //     } else {
        //       console.log(response.data.error_message)
        //     }

        //   })
        // }
      },
      detectingprovider(){
        axios.post('detectingprovider',{
          auth_token: localStorage.getItem('auth_token'),
        }).then(response => {
          this.provider = response.data.data.provider
          for (let i in this.wilayas) {
            if (this.wilayas[i].name == this.provider.wilaya) this.selectedWilaya = this.wilayas[i]
          }
          this.selectedCommunue = this.provider.communue
          this.provider.selectedVehicule = this.provider.delivery_type
          this.provider.selectedService = this.provider.service_type
        })
      }
    }
  }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>